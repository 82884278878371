import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo.png';
import { Loading } from '@viable-inc/fe-common';

export const Maintenance: React.FC = () => {

  const [loading] = useState(false);

  const [viewportHeight, setViewportHeight] = useState('100vh');

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    // 初期設定
    updateViewportHeight();

    // リサイズイベントリスナーを追加
    window.addEventListener('resize', updateViewportHeight);

    // クリーンアップ関数
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  if (loading) {
    return <div className='flex justify-center items-center'><Loading /></div>;
  };

  return (
    <div
      className="flex justify-center items-center"
      style={{ height: viewportHeight, overflow: 'hidden' }}
    >
      <div className="space-y-2 text-center">
        <div className='flex flex-col items-center'>
          <img width={150} src={logo} alt="experts logo" />
        </div>
        <div className="flex flex-col items-center">
          <p>本サービスの提供は停止しております。</p>
          <p>ご相談事などあれば、開発者までお問い合わせください。</p>
        </div>
      </div>
    </div>
  );
};